import React from 'react';
import '../CSS/blog.css';
import '../App.css'
function BB (props){

return(

<div className="block">
<h1>{props.t}</h1>

<section>{props.s}</section>


    <h4 >By {props.a}</h4>
</div>


);





}

export default BB; 
import React from "react";
import '../App.css';
import { Link } from 'react-router-dom';
// import Button from 'react-bootstrat/Button'
// import 'bootstrap/dist/css/bootstrap.min.css'

import {useTranslation} from 'react-i18next';


function BLUEBAR() {

    const { t } = useTranslation();


    return(
        
        <div className="izplav">
       <Link to="/"> <h1 id="NS">{t('Naslov.1')}</h1> </Link>
        <div className="linkovi">
            {/* <!--LINKOVI NA PLAVOJ CRTI--> */}
    {/* <Link to='/'> <a href="Početna" className="link">{t('Početna.1')}</a> </Link> */}
   <Link to='Onama'> <a href="O nama" className="link"> {t('Onama.1')}</a></Link>
   <Link to='kontakti'><a href="kontakti" className="link">{t('Kontakti.1')}</a></Link>
                {/* <!-- <a href="forum.php" class="link">{t('Forum')}</a> --> */}

                <Link to='blog'> <a href="blog" className="link">{t('Blog.1')}</a></Link>
                <Link to='QIA'> <a href='Q&A' className="link">Q&A</a></Link>

                <Link to='članstvo'><a href="članstvo" className="link">{t('Članstvo.1')}</a></Link>
                 <a href="SP-statut.pdf" className="link" target="_blank">Statut</a>

                {/* <!-- <a href="registracija.php" class="link"><?php echo $lang["Prijava"] ?></a> --> */}
                {/* <Link to='jezici'> <a href="jezik" className="link">{t('Jezik.1')}</a></Link> */}
    
    
        </div>
        </div>
    



    );
}


export default BLUEBAR;
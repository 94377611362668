import React from 'react';
import './App.css';
import KONTAKTI from './stranice/kontakti.js';
import POCETNA from './stranice/pocetna.js';
import BLOG from './stranice/blog.js';
import JEZICI from './stranice/jezici.js';
import CLANSTVO from './stranice/članstvo.js'
import ONAMA from './stranice/Onama.js'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import BEGINNING from './blogovi/BEGINNING';
import ACTIVIST from './blogovi/ACTIVIST';

import POSLANO from './stranice/poslano.js'
import QIA from './stranice/Q&A'
import POLSTRAT from './blogovi/polstrat';

// import {useTranslation} from 'react-i18next';


function App() {


  // const { t } = useTranslation();



  return (
 
  <Router>



  <div className="GS" id="GS">


    
    <Switch>
    <Route path="/" exact component={POCETNA} />
    <Route path="/blog" component={BLOG} />
    <Route path="/članstvo" component={CLANSTVO} />
    <Route path="/jezici" component={JEZICI} />
    <Route path="/kontakti" component={KONTAKTI} />
    <Route path="/onama" component={ONAMA} />
    <Route path="/beginning" component={BEGINNING} />
    <Route path="/poslano" component={POSLANO} />
    <Route path="/activist" component={ACTIVIST} />

    <Route path="/polstrat" component={POLSTRAT} />


    <Route path="/QIA" component={QIA} />
    {/* <Route path="" component={} /> */}
    {/* <Route path="" component={} /> */}
    {/* <Route path="" component={} /> */}

    </Switch>
  
  </div>
  </Router>
  );






}



export default App;

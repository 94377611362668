
  import React from "react";
 import '../App.css';
function REDBAR() {
    

return(

        <div className="izcrv">
            <div className="linkovi2">

            <a href="https://www.patreon.com/user?u=24746490" rel="noopener noreferrer" target="_blank"> <img src="/images/Patreon.png" width="49" id="Patreon"alt="Patreon"></img></a>
            
            <a href="https://www.youtube.com/channel/UCLaUCnt6aosZOScCLrswm1Q" rel="noopener noreferrer" target="_blank" id="ytlink"
             className="dslink"><img src="/images/yt.png" alt="Youtube link" id="ytb" width="50" className="slikadolje"></img></a> 

            <a href="https://www.facebook.com/SlavicMovement" target="_blank"  rel="noopener noreferrer" id="Facebook link" className="dslink"> <img
             src='/images/facebook.png' alt="facebook link" width="50" className="slikadolje" id="fb"></img></a> 
            
            <a href="https://www.instagram.com/slavicmovement/" target="_blank"  rel="noopener noreferrer" id="Facebook link" className="dslink"> <img
             src='/images/ig.png' alt="facebook link" width="50" className="slikadolje" id="ig"></img></a> 

<a href="https://t.me/slavicmovement" target="_blank"  rel="noopener noreferrer" id="Facebook link" className="dslink"> <img
             src='/images/dis.png' alt="facebook link" width="50" className="slikadolje" id="dis"></img></a> 

<p id="pa">© 2022 , Slavic Movement</p>
            </div>


        </div>
);
}

export default REDBAR;
import React from 'react';
// import '../App.css';
import REDBAR from '../bars/redbar';
import BLUEBAR from '../bars/bluebar1';
import '../CSS/blog.css'
import '../CSS/q.css'
// import {useTranslation} from 'react-i18next';
// import i18next from 'i18next';
import {useTranslation} from 'react-i18next';

function QIA() {

    const { t } = useTranslation();

  return (
    
<div className="GZ">


<BLUEBAR/>
  <div className="GS0">
  


<div className="pio">

    <h2>{t('Q.10')}</h2>
<br></br>
<p>
{t('AA.10')}
</p>

<br/> <br/>

<h2>{t('Q.20')}</h2>
<br></br>

<p>
{t('A.20')}
</p>

<br/> <br/>  <h2>{t('Q.30')}</h2>
<br></br>

<p>
{t('A.30')}
</p>

<br/> <br/>  <h2>{t('Q.40')}</h2>
<br></br>

<p>
{t('A.40')}
</p>

<br/> <br/>  <h2>{t('Q.50')}</h2>
<br></br>


<p>
{t('A.50')}
</p>

<br/> <br/>  <h2>{t('Q.60')}</h2>
<br></br>


<p>
{t('A.60')}
</p>

<br/> <br/>


<br/> <br/>  <h2>{t('Q.70')}</h2>
<br></br>


<p>
{t('A.70')}
</p>

<br/> <br/>

<br/> <br/>  <h2>{t('Q.80')}</h2>
<br></br>


<p>
{t('A.80')}
</p>

<br/> <br/>

<br/> <br/>  <h2>{t('Q.90')}</h2>
<br></br>


<p>
{t('A.90')}
</p>

<br/> <br/>

<br/> <br/>  <h2>{t('Q.100')}</h2>
<br></br>


<p>
{t('A.100')}
</p>

<br/> <br/>




</div>











          
  {/* <h3>{t('')}</h3> */}


  </div>
  <REDBAR/>
  
  












  
    
  
  </div>
  );






}



export default QIA;

import React from 'react';
import '../CSS/kontakzi.css'
import REDBAR from '../bars/redbar';
import BLUEBAR from '../bars/bluebar1';
import{ init } from 'emailjs-com';
import '../App.css';
import '../CSS/poslano.css'

init("user_r6rJMeJCMLPBMXJeroNfJ");

export default function POSLANO() {


  



  return (
    
<div>

 
<BLUEBAR/>
  <div className=""  id="GS0">


      <h1 className="PP"  >PORUKA PRIMLJENA USKORO ĆEMO VAM SE JAVITI</h1>
      
  <div>

</div>
    




  </div>
  <REDBAR/>
  
<script>
  
</script>

  
  </div>












  
    
  
 
  );






}




import React from 'react';
// import '../App.css';
import REDBAR from '../bars/redbar';
import BLUEBAR from '../bars/bluebar1';
import '../CSS/blogovi.css'

// import {useTranslation} from 'react-i18next';
// import i18next from 'i18next';

function ACTIVIST() {


  return (
    
<div>


<BLUEBAR/>
  <div className="GS0">
  

<h1 id="T">Activist</h1>

<div className="midp">
<p>
Being an activist means that immediately upon sending the form you will be contacted by movement representatives. They will brief you and add you to our encrypted work server where you will communicate with the rest of the activists. It is expected from an activist that they are available at all time to receive and execute tasks, participate in meetings, be creative and advance the cause in any way they can. Please do not sign up for being an activist if you cannot or don't want to 
do the thing mentioned above. <br/>   <br/> 

You can choose to not become an activist and just remain on stand by. This means that you will be counted on to become an activist when the adequate amount of people
that are on stand by in your area gets reached. If you don't become an activist and remain on stand by you will still be invited to join our member servers and will need to reply when we contact you via phone and email.  <br/> <br/> 

If for any reason a member stops meeting the requirements for being an activist or a member on stand by, they can get their status revoked. 




</p>
<br></br> <br/>
<p>Data entry last changed 09.01.2021</p>
</div>











          
  {/* <h3>{t('')}</h3> */}

 

  </div>
  <REDBAR/>
  
  












  
    
  
  </div>
  );






}



export default ACTIVIST;

import React from 'react';
import '../App.css';
import REDBAR from '../bars/redbar';
import BLUEBAR from '../bars/bluebar1';
import '../CSS/blogovi.css'

// import {useTranslation} from 'react-i18next';
// import i18next from 'i18next';

function    POLSTRAT() {


  return (
    
<div>


<BLUEBAR/>
  <div className="GS0">
  
  
<h1 id="T">Polstrat project</h1>

<div className="midp">
<p>
Slavic Movement association is partaking in the <a href="https://polstrat.org" target="_blank" rel="noopener noreferrer">POLSTRAT PROJECT</a>. <br></br>
 
<br></br>
The goal of the project is to connect like minded people into a network that would become an alternative world economy not reliant on the preexisting systems that governments already provide.
The point of this system is to make free thinking nationalist communities around the world fully sef sustainable. All of the needs of an individual would be meet inside of this system. Such a system is active world wide and anyone
who is aware and knows who the enemy is can use it including us Slavs. With strong economic backing that comes with this system it will be much easier to fund and maintain operations of the Slavic Movement
and other friendly nationalist organisations that work with us. If you want to find about more about the project click here --{'>'}   <a href="https://polstrat.org" target="_blank" rel="noopener noreferrer">POLSTRAT PROJECT</a>

  
</p>


</div>











          
  {/* <h3>{t('')}</h3> */}

 

  </div>
  <REDBAR/>
  
  












  
    
  
  </div>
  );






}



export default POLSTRAT;

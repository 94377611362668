import React from "react";
import '../App.css';
import { Link } from 'react-router-dom';




function BLUEBARPOC() {


    return(
        
        <div className="izplav">
       <Link to="/"> <h1 id="NS">Slavic Movement</h1> </Link>

        <div className="linkovi">
            {/* <!--LINKOVI NA PLAVOJ CRTI--> */}
  
                {/* <!-- <a href="registracija.php" class="link"><?php echo $lang["Prijava"] ?></a> --> */}
    
        </div>
        </div>
    



    );
}


export default BLUEBARPOC;
import React from 'react';
import '../App.css';
import REDBAR from '../bars/redbar';
import BLUEBAR from '../bars/bluebar1';
import '../CSS/Onama.css'


import {useTranslation} from 'react-i18next';


function ONAMA() {


  const { t } = useTranslation();




  return (
    
<div>


<BLUEBAR/>
  <div className="GS1" >
  
  <br/>

<h2 id="os" className="greeting">{t('Osnivanje.1')}</h2>
<h2 className="desnon">{t('Odrednice.1')}</h2>

<div id="O1"> 
    <p id=""> {t('O.1')} </p>
</div>


<div id="drugip">

<div id="O2">
<h2 id="gr">{t('Grb.1')}</h2>
<br/>
<p id="o2">{t('O.2')}</p>
</div>
</div>
<div id="POPIS">
 <ol id="odrednice">
  <li>{t('LI.1')} </li>


     <br/>
     <li>{t('LI.2')}</li>
     <br/>
     <li>{t('LI.3')}</li>
     <br/>
    <li>{t('LI.4')}</li>

    <br/>
     <li>{t('LI.5')}</li>
     
     <br/>
    <li>{t('LI.6')}</li>
    <br/>
     <li>{t('LI.7')}</li>
     <br/>
     
     <li>{t('LI.8')}</li>
     <br/>
     <li>{t('LI.9')} </li>
     <br/>
     <li>{t('LI.10')}</li>
     <br/>
     {/* <li>{t('LI.11')}</li> */}
     <br/>
    
 </ol>

 </div>
<section id="a1"> {t('A.1')} </section>




    

 

  </div>
  <REDBAR/>
  
  












  
    
  
  </div>
  );






}



export default ONAMA;

import React from 'react';
import '../App.css';
import REDBAR from '../bars/redbar';
import BLUEBAR from '../bars/bluebar1';
import '../CSS/blogovi.css'

// import {useTranslation} from 'react-i18next';
// import i18next from 'i18next';

function BEGINNING() {


  return (
    
<div>


<BLUEBAR/>
  <div className="GS0">
  
  
<h1 id="T">The beginning</h1>

<div className="midp">
<p>
  This is the first blog written on this page an will serve as a short introduction to the page and the movement. As of writing this the page has been almost fully compleated, there will been
few patches here and there, maybe a comment plug in will be added later on if there is demand for it. If you have any questions you can send them at the contacts page or visit our facebook page
and send us a message there. <br/> <br/> And now few word about the movement. We founded the  movement because we want to provide a legitamete political solution that will unify the Slavs. Our goal
is to create a network of our and alied political parties that will enable the Slavic confederation if elected. We propose a Slavic confederation because the sovereignty of all Slavic states must 
not be infringed. <br/> <br/> What is a confederation?  
In short it is a political union or an alliance that consists of fully sovereign states. You will be able to read more about it when  the Q&A  section arrives. You can  support the  movement 
by following it joining,donating and following us on social networks. If you want to follow the progression of the Slavic movement join our discord group and bookmark this page because all new uppdates
and projects will show up here. 

<br></br> <br/>
Thank you for reading  -Filip
  
</p>


</div>











          
  {/* <h3>{t('')}</h3> */}

 

  </div>
  <REDBAR/>
  
  












  
    
  
  </div>
  );






}



export default BEGINNING;

import React from "react";
// import '../App.css';
import REDBAR from "../bars/redbar";
import BLUEBAR from "../bars/bluebar1";
import "../CSS/blog.css";
import BB from "./BB.js";
import { Link } from "react-router-dom";

// import {useTranslation} from 'react-i18next';
// import i18next from 'i18next';

function BLOG() {
  return (
    <div>
      <BLUEBAR />
      <div className="GS0">
        <Link to="beginning">
          <BB
            t="The Beginning"
            s={
              <img
                alt="Slavic Movement"
                id="BS"
                width="200px"
                src="/images/SS.png"
              ></img>
            }
            a=""
          />
        </Link>
        <Link to="activist">
          <BB
            t="Activism"
            s={
              <img
                alt="Slavic Movement"
                id="BS"
                width="200px"
                src="/images/SS.png"
              ></img>
            }
          />
        </Link>
        {/* <Link to="polstrat"><BB t="Polstrat" s={<img alt="Slavic Movement" id="BS" width="200px" src="/images/polstrat.png"></img>} a="" /></Link> */}

        {/* <Link to="beginning"><BB t="The Beginning" s={<img alt="Slavic Movement"  id="BS" width="200px" src="/images/SS.png"></img> } a="Filip Marić"/></Link> */}

        {/* <h3>{t('')}</h3> */}
      </div>
      <REDBAR />
    </div>
  );
}

export default BLOG;

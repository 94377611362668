














import React from 'react';
import '../App.css';
import '../CSS/jezik.css'
import REDBAR from '../bars/redbar';
import BLUEBAR from '../bars/bluebar1';


// import {useTranslation} from 'react-i18next';
import i18next from 'i18next';

function JEZICI() {
  
  // const { t } = useTranslation();

  function handleClick(lang) {
    i18next.changeLanguage(lang)
  }


  return (
    
<div>


<BLUEBAR/>
  <div className="GS1">
  

     {/* <!-- Ovdje ide sve što piše na stranici --> */}

     



    
     <input  onClick={()=>handleClick('hr')} type="image" src="./images/Hrvat-Srb.png" id="hrsr" class="js" alt="Hrvatski ili Srpski jezik" ></input> 
     <input  onClick={()=>handleClick('pl')} type="image" src="./images/Poljska.png" id="pl" class="js" alt="Język polski" ></input> 
     <input  onClick={()=>handleClick('sk')} type="image" src="./images/Ceska.png" id="slova" class="js" alt="Slovenský jazyk" ></input> 
     <input  onClick={()=>handleClick('uk')} type="image" src="./images/Ukrajina_2.png" id="uk" class="js" alt="Українська мова" ></input> 
     <input  onClick={()=>handleClick('ru')} type="image" src="./images/rus.png" id="ru" class="js" alt="Русский язык" ></input> 
     <input  onClick={()=>handleClick('bg')} type="image" src="./images/bg.png" id="bg" class="js" alt="Български език" ></input> 





 

  </div>
  <REDBAR/>
  
  












  
    
  </div>
  
  );






}



export default JEZICI;

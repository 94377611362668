














import React from 'react';
import '../App.css';
import '../CSS/jezik.css'
import REDBAR from '../bars/redbar';
// import BLUEBAR from '../bars/bluebar1';
import BLUEBARPOC from '../bars/bluebarPOC'
import { Link } from 'react-router-dom';

// import {useTranslation} from 'react-i18next';
import i18next from 'i18next';

function POCETNA() {
  
  // const { t } = useTranslation();

  function handleClick(lang) {
    i18next.changeLanguage(lang)
  }


  return (
    
<div id="GSZ"  className="GSZ">


<BLUEBARPOC/>
  <div className="GS1" id="GS1">
  

     {/* <!-- Ovdje ide sve što piše na stranici --> */}

     



    
     <Link to="Onama"> <input  onClick={()=>handleClick('hr')} type="image" src="./images/Hrvat-Srb.png" id="hrsr" class="js" alt="Hrvatski ili Srpski jezik" ></input> </Link>
    <Link to="Onama"> <input  onClick={()=>handleClick('pl')} type="image" src="./images/Poljska.png" id="pl" class="js" alt="Język polski" ></input> </Link>
    <Link to="Onama"><input  onClick={()=>handleClick('sk')} type="image" src="./images/Slovacka.png" id="slova" class="js" alt="Slovenský jazyk" ></input> </Link>
    <Link to="Onama"><input  onClick={()=>handleClick('uk')} type="image" src="./images/Ukrajina_2.png" id="uk" class="js" alt="Українська мова" ></input> </Link>
    <Link to="Onama"> <input  onClick={()=>handleClick('ru')} type="image" src="./images/rus.png" id="ru" class="js" alt="Русский язык" ></input> </Link>
    <Link to="Onama"><input  onClick={()=>handleClick('bg')} type="image" src="./images/bg.png"  id="bg" class="js" alt="Български език" ></input> </Link>
    <Link to="Onama"><input  onClick={()=>handleClick('cz')} type="image" src="./images/Ceska.png"  id="cz" class="js" alt="" ></input> </Link>
    <Link to="Onama"><input  onClick={()=>handleClick('sl')} type="image" src="./images/Slovenija.png"  id="sl" class="js" alt="" ></input> </Link>
    <Link to="Onama"><input  onClick={()=>handleClick('mk')} type="image" src="./images/Makedonija.png"  id="mk" class="js" alt="" ></input> </Link>
    <Link to="Onama"><input  onClick={()=>handleClick('bl')} type="image" src="./images/Bjelorus.png"  id="be" class="js" alt="" ></input> </Link>






 

  </div>
  <REDBAR/>
  
  












  
    
  </div>
  
  );






}



export default POCETNA;

import React from 'react';
import '../App.css';
import '../CSS/članstvo.css';
import REDBAR from '../bars/redbar';
import BLUEBAR from '../bars/bluebar1';
import { Link } from 'react-router-dom';
import {useTranslation} from 'react-i18next';



// import { Button } from 'react-bootstrap';

function CLANSTVO() {

    const { t } = useTranslation();


  



  return (
    
<div id="sz">


<BLUEBAR/>
  <div className="GS2" id="GS2">
  
  
 



        
        {/* <!-- Ovdje ide sve što piše na stranici --> */}
       
        {/* <iframe class="form" title="doc" src="https://docs.google.com/forms/d/e/1FAIpQLSegK5PjFA9UaE_QaeAZmF6C5WOiqXMIf3CWutcJ0OuoRXg8ew/viewform?embedded=true" width="640" height="750    " frameborder="0" marginheight="0" marginwidth="0">Učitavanje…</iframe> */}


   <form action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSegK5PjFA9UaE_QaeAZmF6C5WOiqXMIf3CWutcJ0OuoRXg8ew/formResponse" id="form" method="POST" >
<div  id="S0">
   <div className="i">
   <input type="text" className="d" required name="entry.1587775855" placeholder = {t('Ime.1')}  />
   </div>
   <br></br>  
   <div className="i">

   <input type="text" required className="d" name="entry.588796141" placeholder={t('C.1')}/>
   </div>
   <br></br>
   <div className="i">

   <input type="number" required name="entry.556473722" placeholder={t('C.2')}/>
   </div>

   <br></br>
   <div className="i">

   <input type="Email" required name="emailAddress" placeholder= {t('Email.1')}/>
   </div>

   <br/>




<div className="i">

   <input type="number" required name="entry.1348350289" placeholder= {t('C.3')}/>
   </div>
  <h6 >{t('C.4')}</h6> 

   <br></br>
   <div className="i">

   <input type="text" required name="entry.435934428" placeholder={t('C.5')}/>
   </div>
   <br></br>
   <div className="i">
   <input type="number" required name="entry.1910040439" placeholder={t('C.6')}/>
   </div> <br/> <br/>


<div className="i">

   <input type="text" required  name="entry.806154339" placeholder={t('C.7')}/>
   </div>
   
   </div>
   
{/* Kraj drugog stupca */}


<div id="s1">
   <div className="i2">

   <input type="text" required name="entry.1655991484" placeholder={t('C.8')}/>
</div> <br/> <br/>
   <div className="i2">

   <input type="text" required name="entry.595352495" placeholder={t('C.9')}/>
</div>
<br/> <br/>
<div className="i2">
   <input type="text" required name="entry.214706665" placeholder={t('C.10')}/>
   </div>
   <br/><br/>

   <div className="i2">

   <input type="text"  required name="entry.2135099536" placeholder={t('C.11')}/>
   </div> <br/> <br/>
   <div className="i2">

   <input type="text" required name="entry.458417243" placeholder={t('C.12')}/>
   </div> <br/> <br/>
   <div className="i2">

   <input type="text" required name="entry.1655245184" placeholder={t('C.13')}/>
</div> <br/> <br/>

<label>
{t('odabirz.1')}
              <br />
<select required className="select"  name="entry.1948228057" >
  <option value="Croatia">{t('C.14')}</option>
  <option value="Serbia">{t('C.15')}</option>
  <option value="Bosnia">{t('C.16')}</option>
  <option value="Montenegro">{t('C.17')}</option>
  <option value="Slovenia">{t('C.18')}</option>
  <option value="Macedonia">{t('C.19')}</option>
  <option value="Bulgaria">{t('C.20')}</option>
  <option value="Poland">{t('C.21')}</option>
  <option value="Czechia">{t('C.22')}</option>
  <option value="Slovakia">{t('C.23')}</option>
  <option value="Russia">{t('C.24')}</option>
  <option value="Ukraine">{t('C.25')}</option>
  <option value="Belorus">{t('C.26')}</option>
  <option value="Non slavic">{t('C.27')}</option>
  <option value="ARHM">{t('C.28')}</option>


</select>
</label>
<h6>{t('C.29')}</h6>


</div>
{/* Kraj redka */}

<div id="s2">

   <div id="mz">

   <label>
{t('C.30')}
                <br />     <br />

   <label class="lb" ><input type="radio" required name="entry.1119832185" value="Male"/>{t('odabirz.2')}</label>

  <label class="lb" >  <input type="radio" required name="entry.1119832185" value="Female"/>{t('odabirz.3')}</label>

</label>

</div>
<br/> 

<br/>

   <div id="activist">

   <label>
{t('C.31')}
     <br/>     <br/>

     <label class="lb" > <input type="radio"  required name="entry.999560275" value="Yes"/>{t('Da.1')}</label>
     <label class="lb" ><input type="radio" required  name="entry.999560275" value="No"/>{t('Da.2')}</label>

  </label>

</div>
<br/>

<div id="standby">
  <label>
{t('C.32')}

                <br /> <br />
     <label class="lb" >  <input type="radio" required name="entry.124771223" value="Yes"/>{t('Da.1')} </label>
     <label class="lb" > <input type="radio" required name="entry.124771223" value="No"/>{t('Da.2')}</label>

     </label>
   <Link to="activist"> <h6>{t('odabirz.4')}</h6> </Link> 


</div>
<br/>
<br/>

<div id="agreep">
              <label>
                {t('C.33')}

  <br/> <br/>

  <label class="lb" >  <input type="radio" required name="entry.1176639339" value="Yes"/>{t('Da.1')}</label>
  <label class="lb" >  <input type="radio" required name="entry.1176639339" value="No"/>{t('Da.2')}</label>
  </label>
</div>


<br/>

<div id="workp">
  <label>
{t('C.34')}

                <br />     <br />

    <label class="lb" >  <input type="radio" required name="entry.57739513" value="Yes"/>{t('Da.1')} </label>
    <label class="lb" > <input type="radio" required  name="entry.57739513" value="No"/>{t('Da.2')} </label>
  </label>
</div>


<br/>

<div id="travelp">
  <label>
{t('C.35')}

                <br /> <br />
     <label class="lb" > <input type="radio" required name="entry.484064035" value="Yes"/> {t('Da.1')}</label>
     <label class="lb" > <input type="radio"  required name="entry.484064035" value="No"/> {t('Da.2')}</label>
  </label>
</div>

<div id="languagep">
<label>


{t('C.36')}

                <br></br>   <br></br>

    <label class="lb" > <input type="radio" required name="entry.1439717611" value="Yes"/>{t('Da.1')} </label>
                <label class="lb" ><input type="radio" required name="entry.1439717611" value="No" /> {t('Da.2')}</label>
                
                <div id="submitd">
<button id="submit" type="submit"> {t('C.37')}</button>
</div>


  </label>
</div>



</div>

  
 

   </form>



{/* <div class="privatnost"> */}
{/* <p>{t('Privatnost.1')} </p> */}
{/* </div> */}
 

    
<img alt="Slavic Movement" id="slikac"src="/images/SS.png"></img>


  


          
</div>
 
</div>
 
  
  );


}



export default CLANSTVO;